import { css } from "@emotion/react";
import { secondaryBgColor } from "../config/theme";
import { Skeleton as MuiSkeleton, SkeletonProps } from "@mui/material";

/**
 * Customized Skeleton component for displaying loading content for texts.
 * Utilizes Material-UI's Skeleton component with additional styling.
 * Secondary app color and without default spacing
 *
 * @param {SkeletonProps} props - Props to be passed to the Skeleton component.
 * @returns {JSX.Element} React JSX Element representing the Skeleton component.
 */

export const Skeleton = (props: SkeletonProps) => {
  return <MuiSkeleton {...props} css={skeletonStyle} animation={"wave"} />;
};

const skeletonStyle = css`
  width: 100%;
  background-color: ${secondaryBgColor};
  transform: scaleY(1);
`;
