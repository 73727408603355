import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { css } from "@emotion/react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactElement, SyntheticEvent, useState } from "react";
import { Button } from "./Button";
import { APP_TOOLTIP_CONSTANTS } from "../constants/global.constants";

interface IAppTooltip {
  color?: "dark" | "light";
  title: string;
  subTitle: string;
  children?: ReactElement;
}

/** Implements the tool tip used in the app with default settings and styles */
export const AppTooltip = (props: IAppTooltip) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (e: SyntheticEvent | Event) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleClose = (e?: SyntheticEvent | Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpen(false);
  };

  return (
    <Tooltip
      arrow
      onClick={handleOpen}
      open={isOpen}
      onClose={handleClose}
      onMouseEnter={handleOpen}
      leaveTouchDelay={Infinity}
      slotProps={{
        arrow: {
          sx: {
            fontSize: "1rem",
            color: "white !important",
          },
        },
        tooltip: {
          sx: {
            background: "transparent",
            padding: 0,
            maxWidth: "none",
          },
        },
        popper: {
          sx: {
            padding: "0.7rem",
          },
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -19],
              },
            },
          ],
        },
      }}
      title={
        <ToolTipContent
          handleBtnClick={handleClose}
          title={props.title}
          subTitle={props.subTitle}
        />
      }
      css={tooltipStyles}
    >
      {props.children ? (
        props.children
      ) : (
        <IconButton
          css={infoButtonStyles(props.color === "dark" ? "black" : "white")}
        >
          <InfoOutlinedIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

const ToolTipContent = (props: {
  handleBtnClick(): void;
  title: string;
  subTitle: string;
}) => {
  return (
    <Stack css={containerStyles}>
      <Typography variant="h1" css={titleStyles}>
        {props.title}
      </Typography>
      <Typography variant="h2" css={subTitleStyles}>
        {props.subTitle}
      </Typography>
      <Button button="text" css={buttonStyles} onClick={props.handleBtnClick}>
        {APP_TOOLTIP_CONSTANTS.BTN}
      </Button>
    </Stack>
  );
};

const containerStyles = css`
  gap: 0.8rem;
  width: 20rem;
  max-width: 90vw;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  color: black;
  box-shadow: 5px 0 25px #2222222e;
`;

const titleStyles = css`
  font-size: clamp(1rem, 5vw, 1.2rem);
  font-weight: 600;
`;

const subTitleStyles = css`
  font-size: clamp(0.875rem, 2vw, 1rem);
`;

const buttonStyles = css`
  display: block;
  margin-left: auto;
  width: fit-content;
`;

const tooltipStyles = css`
  cursor: auto;
  user-select: none;
`;

const infoButtonStyles = (color: string) => css`
  padding: 0.3rem !important; // important to override the style from the MUI tabs
  color: white;
  color: ${color};

  svg {
    font-size: clamp(1rem, 3vw, 1.5rem);
  }
`;
