import { useEffect, useState } from "react";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import { AppTable, AppTableSkeleton } from "../../../components";
import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import {
  IMiniLeagueCaptainsTableRes,
  useGetMiniLeagueCaptainTable,
} from "../../../utils/hooks/useGetMiniLeagueCaptainTable";
import { tableDividerColor, tableHeaderBgColor } from "../../../config/theme";
import { MINI_LEAGUE_PAGE } from "../../../constants/mini-league.constants";
import {
  arrowColumn,
  captainColumn,
  countColumn,
  getCaptainGwPoints,
} from "../../../utils/tableColumns/miniLeagueTableColumns";

interface ICaptainsTable {
  onRowClick: GridEventListener<"rowClick">;
}

/**
 * Component that fetch the data and renders a table
 */
export const CaptainsTable = (props: ICaptainsTable) => {
  const { miniLeagueCaptainsTableData, isLoading } =
    useGetMiniLeagueCaptainTable();

  const [tableColumn, setTableColumn] = useState<
    GridColDef<IMiniLeagueCaptainsTableRes>[]
  >([]);

  useEffect(() => {
    let gameWeekPointsColumns: GridColDef<IMiniLeagueCaptainsTableRes>[] = [];
    if (miniLeagueCaptainsTableData) {
      gameWeekPointsColumns = getCaptainGwPoints(
        miniLeagueCaptainsTableData[0].gwHistoryPoints
      );
    }

    const headers = [
      captainColumn,
      countColumn,
      ...gameWeekPointsColumns,
      { ...arrowColumn, headerName: "Managers that captained him", flex: 1.2 },
    ];

    setTableColumn(headers);
  }, [miniLeagueCaptainsTableData]);

  return (
    <div>
      <Stack css={headerContainerStyle}>
        <Typography variant="h1" css={tableTitleStyle}>
          {MINI_LEAGUE_PAGE.CAPTAINS.TABLE.TITLE}
        </Typography>
        <Typography css={tableSubTitleStyle}>
          {MINI_LEAGUE_PAGE.CAPTAINS.TABLE.SUB_TITLE}
        </Typography>
      </Stack>
      {miniLeagueCaptainsTableData && !isLoading ? (
        <AppTable
          getRowId={(row) => `${row.name.web_name} ${row.team.name}`}
          columns={tableColumn}
          rows={miniLeagueCaptainsTableData}
          dividerColor={tableDividerColor}
          onRowClick={props.onRowClick}
        />
      ) : (
        <AppTableSkeleton />
      )}
    </div>
  );
};

const headerContainerStyle = css`
  gap: 0.5rem;
  background: ${tableHeaderBgColor};
  border-radius: 1rem 1rem 0 0;
  padding-inline: 1rem;
  padding-block: 1rem 0.3rem;
`;

const tableTitleStyle = css`
  font-size: clamp(1.125rem, 5vw, 2rem);
  font-weight: 500;
`;

const tableSubTitleStyle = css`
  font-size: clamp(0.675rem, 1.5vw, 0.9rem);
  color: #dfdfdf;
`;
