import { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  refreshSessionSupabase,
  signOutSupabase,
} from "../pages/auth-pages/utils/authHelpers";
import { ERROR_MESSAGES } from "../config/contextApi.constants";
import { LoadingScreen } from "../components";
import { FPL_CHAMP_ENDPOINTS } from "../config/fplChampEndpoints.constants";
import { useGlobalInfoContext } from "./GlobalInfoContext";
import { axiosInstance } from "../config/axios.config";
import { SESSION_STORAGE } from "../config/app.config";
import { PATH_PARAMS } from "../config/routes";
import { getLeagueById } from "../utils/general.helpers";
import { ROLLBAR_USER } from "../config/rollbar.config";
import { IClassicLeagues } from "../utils/types/types";

interface IUserProvider {
  user: IUser | null | undefined;
  setUser: React.Dispatch<React.SetStateAction<IUser | null | undefined>>;
  signOutUser: () => void;
}

export interface IUser {
  email: string;
  email_verified: boolean;
  id: string;
  teamId: number;
  selectedLeagueId: string | undefined;
  favoriteLeagueId: string | undefined;
}

const userContext = createContext<IUserProvider | undefined>(undefined);

/**
 * Custom hook to access the user context.
 *
 * @returns {IUserProvider} The context value containing the user state, setter, and sign-out function.
 * @throws {Error} Throws an error if the context is not provided.
 */
export const useUserContext = () => {
  const userState = useContext(userContext);

  if (!userState) throw new Error(ERROR_MESSAGES.NO_USER_CONTEXT_PROVIDER);

  return userState;
};

/**
 * Context provider for managing user state and authentication.
 *
 * @description
 * Provides context for managing the user state and authentication, including:
 * - `user`: The current user or `null` if not authenticated.
 * - `setUser`: Function to update the user state.
 * - `signOutUser`: Function to sign out the user.
 *
 * Handles user session refresh, updates user state based on session data, and manages
 * user-related loading screens and league information.
 *
 * Display a loading screen while user auth state is being retrieved
 */

export const UserContextProvider = () => {
  const [user, setUser] = useState<IUser | null | undefined>();
  const [isLoadingScreenOpen, setIsLoadingScreenOpen] = useState(false);
  const {
    selectedLeague,
    setSelectedLeague,
    leagues,
    setLeagues,
    setSelectedLeagueIdUrl,
  } = useGlobalInfoContext();
  const params = useParams();

  useEffect(() => {
    (async function () {
      try {
        const user = await refreshSessionSupabase();

        if (
          user &&
          sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID) &&
          sessionStorage.getItem(SESSION_STORAGE.TEMP_SELECTED_LEAGUE_ID)
        ) {
          return setUser({
            ...user,
            teamId: Number(
              sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID)
            ),
            selectedLeagueId: sessionStorage.getItem(
              SESSION_STORAGE.TEMP_SELECTED_LEAGUE_ID
            )!,
          });
        }

        if (user && sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID)) {
          return setUser({
            ...user,
            teamId: Number(
              sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID)
            ),
          });
        }

        // Rollbar user
        /** save user data in session to access in error boundary component */
        if (user) {
          sessionStorage.setItem(
            ROLLBAR_USER,
            JSON.stringify({ email: user.email, teamId: user.teamId })
          );
        }

        setUser(user);
      } catch (e) {
        setUser(null);
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoadingScreenOpen(true);
    const closeLoadingScreen =
      (user && !user.teamId) || // user registered and still doesn't have teamId
      (user && user.teamId && selectedLeague) || // user registered and has teamId
      user === null; // user is signed out or doesn't have account

    if (closeLoadingScreen) {
      setIsLoadingScreenOpen(false);
    }
  }, [user, selectedLeague]);

  useEffect(() => {
    if (user && user.teamId) {
      (async function () {
        const { data } = await axiosInstance.get<IClassicLeagues[]>(
          FPL_CHAMP_ENDPOINTS.GET_LEAGUES(String(user.teamId))
        );

        setLeagues(data);
      })();
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;
    if (leagues.length === 0) return;
    if (!params[PATH_PARAMS.LEAGUE_ID]) return;

    const leagueId = [
      params[PATH_PARAMS.LEAGUE_ID], // league in URL
      user.favoriteLeagueId,
      user.selectedLeagueId,
      leagues[0].id,
    ];

    for (let i = 0; i < leagueId.length; i++) {
      const league = getLeagueById(leagues, leagueId[i]!);
      if (league) {
        setSelectedLeague(league);
        break;
      }
    }
  }, [leagues, user, params]);

  useEffect(() => {
    (async () => {
      if (selectedLeague) {
        await setSelectedLeagueIdUrl(String(selectedLeague.id));
      }
    })();
  }, [selectedLeague]);

  const signOutUser = async () => {
    await signOutSupabase();
    setUser(null);
    setIsLoadingScreenOpen(false);
  };

  return (
    <userContext.Provider
      value={{
        user,
        setUser,
        signOutUser,
      }}
    >
      {isLoadingScreenOpen && <LoadingScreen />}
      <Outlet />
    </userContext.Provider>
  );
};
