import { css } from "@emotion/react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { bgColor } from "../../../config/theme";
import { primaryGradient } from "../../../config/styles";
import { formateNumbers } from "../../../utils/general.helpers";

interface IMoreInfoModal {
  open: boolean;
  info: { [key: string]: number };
  onClose: () => void;
}

/**
 * A pop up model that render info in a grid
 * Takes an info object and renders a label using the key and a value using the value
 * of the info object
 */

export const MoreInfoModal = (props: IMoreInfoModal) => {
  return (
    <Modal open={props.open} css={modalRootStyles} onClose={props.onClose}>
      <Stack css={modalStyles}>
        <Typography css={titleStyles}>More Info</Typography>
        <IconButton css={closeButtonStyles} onClick={props.onClose}>
          <CloseRoundedIcon />
        </IconButton>
        <Stack css={infoContainerStyle}>
          {Object.entries(props.info).map(([key, value]) => (
            <Box key={key} css={infoStyles}>
              <Typography>{formateNumbers(value)}</Typography>
              <Typography>{key}</Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
};
const modalRootStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const modalStyles = css`
  position: relative;
  background: ${bgColor};
  margin: 1rem;
  padding: 2rem;
  border-radius: 1.5rem;
  width: 100%;
  max-width: 650px;
`;

const titleStyles = css`
  font-weight: 900;
  font-size: clamp(1.5rem, 3.5vw, 2rem);
  margin-bottom: 1rem;
`;

const infoContainerStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`;

const infoStyles = css`
  p:nth-of-type(1) {
    ${primaryGradient}
    font-weight: 900;
    font-size: clamp(1.4rem, 5vw, 3rem);
    line-height: 1;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }

  p:nth-of-type(2) {
    font-size: clamp(0.875rem, 1vw, 1rem);
  }
`;

const closeButtonStyles = css`
  position: absolute;
  top: 1rem;
  right: 1rem;

  * {
    color: #414141;
  }
`;
