import { AppDropDown } from "./AppDropDown";
import { useAtom } from "jotai";
import { phasesAtom } from "../utils/atoms/globalInfoAtom";
import { SelectChangeEvent } from "@mui/material";
import { PHASE_DROPDOWN } from "../constants/global.constants";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../config/routes";

/** Drop down menu to select the phase (updates the URL) */
export const SelectPhaseDropDown = () => {
  const [phases] = useAtom(phasesAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDropDownChange = (e: SelectChangeEvent) => {
    searchParams.set(PATH_PARAMS.SELECTED_PHASE, e.target.value);
    setSearchParams(searchParams);
  };

  return (
    <AppDropDown
      label={PHASE_DROPDOWN.TITLE}
      isLoading={Boolean(
        phases && phases.length && searchParams.get(PATH_PARAMS.SELECTED_PHASE)
      )}
      onChange={handleDropDownChange}
      value={searchParams.get(PATH_PARAMS.SELECTED_PHASE)}
      loadingText={PHASE_DROPDOWN.LOADING_TEXT}
      items={
        phases &&
        phases.map((phase) => ({
          value: phase.id,
          label: phase.name,
        }))
      }
    />
  );
};
