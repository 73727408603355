import { Button as MuiButton, ButtonProps, Typography } from "@mui/material";
import IntroLogo from "../../../assets/auth-pages/introLogo.svg";
import { css } from "@emotion/react";
import { INTRO_PAGE_CONTENT } from "../../../constants/auth-pages.constants";
import { mq } from "../../../config/styles";
import { Helmet } from "react-helmet";
import { ROUTES } from "../../../config/routes";
import { Link, LinkProps, useLocation } from "react-router-dom";

/** Renders the app into page */
export const IntroView = () => {
  const location = useLocation();
  return (
    <main css={into}>
      <Helmet>
        <title>{INTRO_PAGE_CONTENT.META_DATA.TITLE}</title>
      </Helmet>
      <img alt="Fpl Logo" src={IntroLogo} />
      <Typography variant="h1">{INTRO_PAGE_CONTENT.TITLE}</Typography>
      <Typography>{INTRO_PAGE_CONTENT.BODY}</Typography>

      <div>
        <Button
          LinkComponent={Link}
          state={location.state}
          to={ROUTES.REGISTER_PAGE}
          variant="gradient"
          css={introButtonPrim}
        >
          {INTRO_PAGE_CONTENT.REGISTER_BUTTON}
        </Button>
        <Button
          LinkComponent={Link}
          state={location.state}
          to={ROUTES.SIGN_IN_PAGE}
          variant="gradient"
          css={introButtonSec}
        >
          {INTRO_PAGE_CONTENT.SIGN_IN_BUTTON}
        </Button>
      </div>
    </main>
  );
};

const Button = (props: LinkProps & ButtonProps) => <MuiButton {...props} />;

const into = css`
  flex-grow: 1; /* fill page height */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 2.5rem;
  img {
    /* width: 18.1875rem; */
    height: 21.125rem;
    object-fit: cover;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    ${mq["md"]} {
      text-align: center;
    }
  }

  p {
    color: #dddedf;
    max-width: 46.3125rem;
    ${mq["md"]} {
      font-size: 1.5em;
      text-align: center;
    }
  }

  div {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
`;

const introButtonPrim = css`
  border-radius: 1rem;
  height: 3.937rem;
  width: 12.5rem;
  font-size: clamp(1.125rem, 3vw, 1.5rem);
  font-weight: 600;
  ${mq["md"]} {
    border-radius: 1.5rem;
    height: 4.7rem;
    width: 14rem;
  }
`;

const introButtonSec = css`
  ${introButtonPrim}
  width: fit-content;
  max-width: fit-content;
  min-width: fit-content;
  background: none;
  color: #c4c4c4;
`;
