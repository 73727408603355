import { css } from "@emotion/react";
import { ErrorMessageProps, ErrorMessage as FormikErrorMessage } from "formik";
import { statusColor } from "../config/theme";

interface IErrorMassage extends ErrorMessageProps {}

/**
 * This component renders an error message provided by Formik for a specific form field.
 * It styles the error message consistently within your application.
 *
 * @param {IErrorMassage} props - The component props.
 * @param {string} props.name - The name of the form field associated with the error message.
 *
 * @returns {JSX.Element} - Renders a styled div containing the error message.
 */

export const ErrorMessage = ({ name }: IErrorMassage) => {
  return (
    <div css={error}>
      <FormikErrorMessage name={name} />
    </div>
  );
};

const error = css`
  margin-top: 0.25rem;
  margin-left: 1.5rem;
  color: ${statusColor.error};
  font-size: 0.75rem;
`;
