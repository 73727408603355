import { useUserContext } from "../context/UserContext";
import { Navigate, useLocation } from "react-router-dom";
import { useGetUrl } from "../utils/hooks/useGetUrl";

/**
 * This component acts as a gatekeeper, ensuring that only unauthenticated users can access certain pages.
 * It redirects logged-in users to the designated home page (`ROUTES.HOME_PATH`).
 *
 * @param {object} props - Contains information about what to protect.
 * @param {React.ReactNode} props.children - The content to be displayed if access is granted.
 * @returns {React.ReactNode} - Returns either the protected content or redirects to the home page if logged in.
 *
 */

export const AuthPageGuard = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUserContext();
  const location = useLocation();
  const { getHomePath } = useGetUrl();

  // navigate the user to the page they came from or to home page if the "from" page doesn't exist
  if (user)
    return (
      <Navigate to={location.state?.from?.pathname || getHomePath()} replace />
    );

  return <>{children}</>;
};
