import { GridColDef } from "@mui/x-data-grid";

export const columnNumber: GridColDef = {
  field: "table rank",
  headerName: "Rank",
  flex: 0.7,
  maxWidth: 100,
  align: "center",
  headerAlign: "center",
  renderCell: (value) => {
    const pageSize = value.api.state.pagination.paginationModel.pageSize;
    const pageNumber = value.api.state.pagination.paginationModel.page;
    return (
      value.api.getAllRowIds().indexOf(value.id) + 1 + pageSize * pageNumber
    );
  }, // row number
};
