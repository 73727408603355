import { css, keyframes } from "@emotion/react";

const breakpoints: { [index: string]: number } = {
  sm: 500,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1800,
};

export const mq = Object.keys(breakpoints)
  .map((key) => [key, breakpoints[key]] as [string, number])
  .reduce((prev, [key, breakpoint]) => {
    prev[key] = `@media (min-width: ${breakpoint}px)`;
    return prev;
  }, {} as { [index: keyof typeof breakpoints]: string });

export const container = css`
  max-width: 100%;
  padding: 1rem;

  ${mq["md"]} {
    padding: 1.5rem;
  }

  ${mq["xl"]} {
    padding: 2rem;
  }
`;

export const title = css`
  font-size: clamp(1.3rem, 3vw, 2rem);
`;

export const cardBorderRadius = css`
  border-radius: 1rem;
`;

export const homeCardBgGradient = css`
  background: radial-gradient(
    circle at top right,
    #ffffff70,
    #2a2b3d,
    #2a2b3d,
    #2a2b3d
  );
`;

export const homeCardBgColor = css`
  background: #2a2b3d;
`;

export const transition_all = css`
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
`;

const pulseKeyframes = keyframes`
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
`;

export const animatePulse = css`
  animation: ${pulseKeyframes} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

export const divider = css`
  border: 1.21px solid #383846;
`;

export const shadow = css`
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

export const poppinsFontFamily = css`
  font-family: "Poppins", sans-serif;
`;

export const primaryGradient = css`
  background: linear-gradient(to right, #00ff87, #02efff);
`;

export const customScrollbar = css`
  ::-webkit-scrollbar {
    --height: 5px;
    height: var(--height);
    width: var(--height);
  }

  ::-webkit-scrollbar-track {
    background: #bfbfbf;
    margin: 1.5rem;
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb {
    ${primaryGradient};
    border-radius: 9999px;
  }
`;
