export const VIEW_TEAM_PAGE = {
  SQUAD_VALUE: "Squad Value",
  MONEY_IN_THE_BANK: "Money in the bank",
  ALL_SEASON_TRANSFERS: "All season transfers",
  POINTS_THIS_GW: "Points this GW",
  OVERALL_POINTS: "Overall points",
  OVERALL_RANK: "Overall Rank",
  GW_RANK: "GW rank",
  RANK_DIFFERENCE: "Rank Difference",
  TRANSFERS: "Transfers",
  GW_POINTS: "GW Points",
  OVERALL_RANK_LABEL: "Overall Rank",
  VIEW_MORE: "View More >",
  PATH_SYMBOL: ">",
  POINTS_SUFFIX: " pts",
  TRANSFERS_COST_PREFIX: "( ",
  TRANSFERS_COST_SUFFIX: ")",
  NUMBER_OF_PLAYERS: "Number of Players",
};

import tripleCapChip from "../assets/view-team/3xc.svg";
import freeHitChip from "../assets/view-team/free-hit.svg";
import wildCardChip from "../assets/view-team/wild-card.svg";
import bechBoostChip from "../assets/view-team/bench-boost.svg";

export const FPL_CHIPS: { [key: string]: { title: string; img?: string } } = {
  "3xc": { title: "Triple Captain chip played!", img: tripleCapChip },
  bboost: { title: "Bench Boost chip played!", img: bechBoostChip },
  wildcard: { title: "Wildcard chip played!", img: wildCardChip },
  freehit: { title: "Free Hit chip played!", img: freeHitChip },
};
