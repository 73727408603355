import { SelectChangeEvent } from "@mui/material";
import { useGlobalInfoContext } from "../context/GlobalInfoContext";
import { GAME_WEEK_DROPDOWN_CONTENT } from "../constants/global.constants";
import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "../config/routes";
import { AppDropDown } from ".";
import { HIGHLIGHTS_PAGE_CONTENT } from "../constants/highlightsPage.constants";
import { useEffect } from "react";

/** A dropdown menu to set the future game week that update the URL (fgw) */
export const SelectFutureGameWeekDropDown = () => {
  const { liveGameWeek } = useGlobalInfoContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = async (event: SelectChangeEvent) => {
    searchParams.set(QUERY_PARAMS.FUTURE_GAME_WEEK, event.target.value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!searchParams.get(QUERY_PARAMS.FUTURE_GAME_WEEK) && liveGameWeek) {
      searchParams.set(QUERY_PARAMS.FUTURE_GAME_WEEK, String(liveGameWeek));
      setSearchParams(searchParams, { replace: true });
    }
  }, [liveGameWeek]);

  return (
    <AppDropDown
      label={HIGHLIGHTS_PAGE_CONTENT.SELECT_GAME_WEEK_LABEL}
      isLoading={Boolean(
        liveGameWeek && searchParams.get(QUERY_PARAMS.FUTURE_GAME_WEEK)
      )}
      onChange={handleChange}
      value={searchParams.get(QUERY_PARAMS.FUTURE_GAME_WEEK)!}
      loadingText={GAME_WEEK_DROPDOWN_CONTENT.LOADING_TEXT}
      items={Array.from({ length: 38 }).map((_, index) => ({
        value: index + 1,
        label: GAME_WEEK_DROPDOWN_CONTENT.DROPDOWN_ITEM_TEXT(
          index + 1,
          liveGameWeek === index + 1
        ),
      }))}
    />
  );
};
