import { css } from "@emotion/react";
import { animatePulse } from "../../config/styles";

export const root = (isSubmitting: boolean) => css`
  ${isSubmitting
    ? css`
        ${animatePulse}
        pointer-events: none;
      `
    : null}
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
