import { useEffect, useState } from "react";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import {
  AppTable,
  AppTableSkeleton,
  Button,
  RankIndicator,
} from "../../../components";
import { columnNumber } from "../../../utils/muiTableColumns";
import {
  IMiniLeagueStanding,
  useGetMiniLeagueData,
} from "../../../utils/hooks/useGetMiniLeagueData";
import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import {
  bgColorDark,
  secondaryColor,
  tableDividerColor,
  tableHeaderBgColor,
} from "../../../config/theme";
import { MINI_LEAGUE_PAGE } from "../../../constants/mini-league.constants";
import { mq, transition_all } from "../../../config/styles";
import {
  IMiniLeagueStandingsCaptainHistoryRes,
  useGetMiniLeagueStandingsTableHistory,
} from "../../../utils/hooks/useGetMiniLeagueStandingsTableHistory";
import {
  extraColumns,
  getGameWeekColumn,
  liveGWCaptainPointsColumn,
  teamColumn,
  getStandingsTableHistoryRows,
} from "../../../utils/tableColumns/miniLeagueTableColumns";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../../config/routes";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";

type gameWeekCaptainsType =
  IMiniLeagueStandingsCaptainHistoryRes["gameWeekCaptains"];
interface IMiniLeagueStandingsTableRow extends IMiniLeagueStanding {
  gameWeekCaptains?: gameWeekCaptainsType;
}

type IMiniLeagueStandingsTableColumns =
  | GridColDef<IMiniLeagueStanding>
  | GridColDef<gameWeekCaptainsType>;

/**
 * Fetch and render the standings table
 */
export const MiniLeagueStandingsTable = () => {
  const [searchParams] = useSearchParams();
  const { liveGameWeek } = useGlobalInfoContext();
  const [isLoading, setIsLoading] = useState(false);
  const [tableRows, setTableRows] = useState<IMiniLeagueStandingsTableRow[]>(
    []
  );

  const [tableColumn, setTableColumn] = useState<
    IMiniLeagueStandingsTableColumns[]
  >([]);

  const [addCaptainsHistory, setAddCaptainsHistory] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const { miniLeagueStandings, hasNext } = useGetMiniLeagueData({
    pageNumber: paginationModel.page + 1,
  });

  const getCaptainsHistory = useGetMiniLeagueStandingsTableHistory({
    pageNumber: paginationModel.page + 1,
  });

  const navigate = useNavigateState();
  const { getTeamViewPath } = useGetUrl();

  const setHistoryRows = async () => {
    const miniLeagueCaptainsHistory = await getCaptainsHistory();
    if (!miniLeagueCaptainsHistory) return;

    const rows = getStandingsTableHistoryRows(
      miniLeagueStandings!,
      miniLeagueCaptainsHistory
    );

    setTableRows(rows);

    return getGameWeekColumn(miniLeagueCaptainsHistory[0].gameWeekCaptains);
  };

  const setTableHeaders = (
    captainsArray: GridColDef<IMiniLeagueStanding>[] = []
  ) => {
    const headers: GridColDef[] = [
      {
        ...columnNumber,
        renderCell: ({ row }: { row: IMiniLeagueStanding }) => (
          <Stack
            height={"100%"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <RankIndicator rank={row.rank} lastRank={row.last_rank} />
            {row.rank_sort}
          </Stack>
        ),
      },
      teamColumn,
      ...extraColumns,
      {
        ...liveGWCaptainPointsColumn,
        headerName: `C.Pts GW${searchParams.get(
          PATH_PARAMS.SELECTED_GAME_WEEK
        )}`,
      },
      ...captainsArray,
    ];

    if (
      searchParams.get(PATH_PARAMS.SELECTED_PHASE) &&
      searchParams.get(PATH_PARAMS.SELECTED_PHASE) === "1"
    ) {
      setTableColumn(headers);
    } else {
      headers.splice(4, 1);
      setTableColumn(headers);
    }
  };

  useEffect(() => {
    (async () => {
      if (miniLeagueStandings) {
        setIsLoading(true);

        if (addCaptainsHistory) {
          const historyColumns = await setHistoryRows();
          setTableHeaders((historyColumns ? historyColumns : []) as any);
        } else {
          setTableHeaders();
          setTableRows(miniLeagueStandings);
        }

        setIsLoading(false);
      }
    })();
  }, [miniLeagueStandings, addCaptainsHistory]);

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(getTeamViewPath(params.row.entry));
  };

  const handleShowCaptainHistoryClick = () => {
    setAddCaptainsHistory(!addCaptainsHistory);
  };

  return (
    <div>
      <Stack css={tableHeaderContainerStyles}>
        <Typography css={tableTitleStyle}>
          {MINI_LEAGUE_PAGE.TABLE.TITLE}
        </Typography>

        {liveGameWeek && liveGameWeek > 1 && (
          <Button
            css={btnStyles(addCaptainsHistory)}
            onClick={handleShowCaptainHistoryClick}
          >
            <Typography data-size="lg">
              {MINI_LEAGUE_PAGE.TABLE.BTN_TITLE}
            </Typography>
            <Typography data-size="sm">
              {MINI_LEAGUE_PAGE.TABLE.SMALL_BTN_TITLE}
            </Typography>
            <ArrowForwardIcon />
          </Button>
        )}
      </Stack>
      {miniLeagueStandings && !isLoading ? (
        <AppTable
          getRowHeight={() => "auto"}
          columns={tableColumn}
          rows={tableRows}
          dividerColor={tableDividerColor}
          hasNext={hasNext}
          onRowClick={handleRowClick}
          // Pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          // Page Count
          rowCount={-1} // unknown page count (MUI)
        />
      ) : (
        <AppTableSkeleton />
      )}
    </div>
  );
};
const tableTitleStyle = css`
  font-size: clamp(1.125rem, 5vw, 2rem);
  font-weight: 500;
  white-space: nowrap;
`;

const tableHeaderContainerStyles = css`
  background: ${tableHeaderBgColor};
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const btnStyles = (isActive: boolean) => css`
  ${transition_all}
  height: fit-content;
  padding: 0.3rem 1.2rem;
  color: #ffffff;
  background-color: ${bgColorDark} !important;
  opacity: 0.95;
  border-radius: 999999px;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  p[data-size="sm"] {
    display: block;
    font-size: 0.875rem;
  }
  p[data-size="lg"] {
    display: none;
  }

  ${mq["sm"]} {
    p[data-size="sm"] {
      display: none;
    }
    p[data-size="lg"] {
      display: block;
    }
  }

  :hover {
    opacity: 1;
  }

  ${isActive &&
  css`
    background-color: ${secondaryColor} !important;
  `}
`;
