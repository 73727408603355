import { css } from "@emotion/react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { secondaryColor } from "../config/theme";

/** Renders the app link component */
export const Link = ({ to, ...props }: LinkProps) => {
  return <RouterLink to={to} css={linkStyle} {...props} />;
};

const linkStyle = css`
  color: ${secondaryColor};
  text-decoration: none;
`;
