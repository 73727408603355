import { NavLink } from "react-router-dom";
import { css } from "@emotion/react";
import { secondaryBgColor, secondaryColor } from "../../config/theme";
import { ButtonBase, Stack, Typography } from "@mui/material";
import { transition_all } from "../../config/styles";
import { useNavigationItem } from "../../utils/hooks/useNavigationItems";

/**
 * This is the main navigation bar in the app the fixed navigation bar at bottom of screen
 */
export const MainAppNavigationMenu = () => {
  const MAIN_NAVIGATION = useNavigationItem().slice(0, 5);

  return (
    <nav css={navStyles}>
      <Stack
        component={"ul"}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {MAIN_NAVIGATION.map((navItem, index) => (
          <Stack
            key={index}
            width={"100%"}
            component={"li"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ButtonBase css={navItemStyle}>
              <NavLink css={navItemStyle} to={navItem.path}>
                <Stack alignItems={"center"} justifyContent={"center"} gap={1}>
                  <navItem.icon
                    fill={navItem.isActivePath ? secondaryColor : undefined}
                  />
                  <Typography
                    variant="h1"
                    css={navItemTitleStyles(navItem.isActivePath)}
                  >
                    {navItem.title}
                  </Typography>
                </Stack>
              </NavLink>
            </ButtonBase>
          </Stack>
        ))}
      </Stack>
    </nav>
  );
};

const navStyles = css`
  width: 100%;
  height: 4.875rem;
  background: ${secondaryBgColor};

  ul {
    width: 100%;
    height: 100%;

    li {
      flex-grow: 1;
      height: 100%;
    }
  }
`;

const navItemStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const navItemTitleStyles = (isPath: boolean) => css`
  ${transition_all}
  color: ${secondaryColor};
  font-size: 0;
  opacity: 0;

  ${isPath &&
  css`
    font-size: 0.875rem;
    opacity: 1;
  `}
`;
