import { createContext, useContext, useEffect, useState } from "react";
import { IClassicLeagues, IGlobalInfo } from "../utils/types/types";
import { FPL_CHAMP_ENDPOINTS } from "../config/fplChampEndpoints.constants";
import { axiosInstance } from "../config/axios.config";
import { isAxiosError } from "axios";
import { ErrorModal } from "../components";
import { MODAL_ERROR_MESSAGES } from "../constants/global.constants";
import { ERROR_MESSAGES } from "../config/contextApi.constants";
import { supabase } from "../config/supabase";
import { phasesAtom } from "../utils/atoms/globalInfoAtom";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { useNavigateState } from "../utils/hooks/useNavigateState";
import { setSelectedLeagueInUserMetaData } from "../utils/general.helpers";
import { IUser } from "./UserContext";

interface IGlobalInfoContext {
  selectedLeague: IClassicLeagues | undefined;
  setSelectedLeague: React.Dispatch<
    React.SetStateAction<IClassicLeagues | undefined>
  >;
  leagues: IClassicLeagues[];
  setLeagues: React.Dispatch<React.SetStateAction<IClassicLeagues[]>>;
  liveGameWeek: number | undefined;
  setSelectedLeagueIdUrl(leagueId: string): Promise<IUser | undefined>;
}

const GlobalInfoContext = createContext<IGlobalInfoContext | undefined>(
  undefined
);

/**
 * Custom hook to access the global information context.
 *
 * @returns {IGlobalInfoContext} The context value containing the global state and setters.
 * @throws {Error} Throws an error if the context is not provided.
 */

export const useGlobalInfoContext = () => {
  const globalInfoState = useContext(GlobalInfoContext);
  if (!globalInfoState) throw new Error(ERROR_MESSAGES.NO_GLOBAL_INFO_PROVIDER);
  return globalInfoState;
};

/**
 * Context provider for managing global information related to leagues and game weeks.
 *
 * Provides context for:
 * - `leagues`: List of available leagues.
 * - `selectedLeague`: The currently selected league.
 * - `liveGameWeek`: The current game week.
 * - `setLeagues`: Function to update the list of leagues.
 * - `setSelectedLeague`: Function to update the selected league.
 * - `setLiveGameWeek`: Function to update the current game week.
 *
 * Retrieves global info and API endpoints on component mount.
 * Display error if game is being updated (between game-weeks).
 */

export const GlobalInfoContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [, setPhases] = useAtom(phasesAtom);
  const [leagues, setLeagues] = useState<IClassicLeagues[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<
    IClassicLeagues | undefined
  >(undefined);

  const [liveGameWeek, setLiveGameWeek] = useState<number | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const { pathname, search } = useLocation();
  const navigate = useNavigateState();

  useEffect(() => {
    (async function () {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();

        if (session) {
          const { data } = await axiosInstance.get<IGlobalInfo>(
            FPL_CHAMP_ENDPOINTS.GLOBAL_INFO
          );
          setPhases(data.phases);
          setLiveGameWeek(data.current_gw);
        }
      } catch (e) {
        if (isAxiosError(e)) {
          if (
            e.response?.data.error.details.response ===
            MODAL_ERROR_MESSAGES.GAME_WEEK_IS_UPDATING
          ) {
            setIsOpen(true);
          }
        } else {
          throw e;
        }
      }
    })();
  }, []);

  const setSelectedLeagueIdUrl = async (leagueId: string) => {
    const path = pathname.split("/");
    path[2] = leagueId;

    navigate(path.join("/") + search, { replace: true });
    const newUser = await setSelectedLeagueInUserMetaData(leagueId);
    return newUser;
  };

  return (
    <GlobalInfoContext.Provider
      value={{
        selectedLeague,
        setSelectedLeague,
        setSelectedLeagueIdUrl,
        leagues,
        setLeagues,
        liveGameWeek,
      }}
    >
      <ErrorModal
        isOpen={isOpen}
        message="The Game is being updated come back later"
      />
      {children}
    </GlobalInfoContext.Provider>
  );
};
