import { Box, Stack } from "@mui/material";
import { css } from "@emotion/react";
import React from "react";
import { mq, shadow } from "../config/styles";
import { bgColor, primaryColor } from "../config/theme";
import { Skeleton } from "./Skeleton";
import playerShirt from "../assets/view-team/player-shirt.svg";

interface IPlayer {
  name: string;
  points: string | number;
  playerShirt: string;
  fieldPosition: string;
  children?: React.ReactNode;
}

/**
 * Component that renders the player shirt with the a name field below the shirt
 * and a floating circle that display the points
 */
export const Player = (props: IPlayer) => {
  return (
    <Stack css={playerContainerStyle(props.fieldPosition === "GK")}>
      <img src={props.playerShirt} />

      <span css={playerPoints}>{props.points}</span>

      <Box css={playerInfoStyle}>
        <p>{props.name}</p>
        {/* <p>{player.op_team_name}</p> */}
      </Box>

      {props.children}
    </Stack>
  );
};

export const PlayerSkelton = () => {
  return (
    <Stack css={playerContainerStyle(false)}>
      <img src={playerShirt} />
      <span css={[playerPoints, skeletonPlayerPoints]}></span>
      <Skeleton css={[playerInfoStyle, skeletonPlayerInfoStyle]} />
    </Stack>
  );
};

const playerContainerStyle = (isGK: boolean) => css`
  position: relative;
  justify-content: center;
  align-items: center;
  padding-block: 0.5rem;

  img {
    max-width: clamp(3.5rem, 9vw, 6rem);
  }

  ${mq["md"]} {
    img {
      max-width: clamp(2.5rem, 8vw, 5rem);
    }
  }

  ${isGK &&
  css`
    margin-inline: 0.5rem 1.5rem;
  `}
`;

const playerInfoStyle = css`
  background-color: white;
  width: clamp(4rem, 15vw, 8rem);
  border-radius: 0.6rem;
  overflow: hidden;
  ${shadow}

  P {
    margin: 0;
    font-weight: 600;
    color: black;
    text-align: center;
  }

  & > p:nth-of-type(1) {
    padding-top: clamp(0.3rem, 1.1vw, 0.5rem);
    padding-bottom: clamp(0.2rem, 1vw, 0.4rem);
    padding-inline: 0.5rem;
    font-size: clamp(0.825rem, 1.5vw, 1.1rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > p:nth-of-type(2) {
    display: inline-block;
    font-size: clamp(0.75rem, 2vw, 0.875rem);
    padding-block: clamp(0.1rem, 0.5vw, 0.2rem);
    background-color: rgba(226, 66, 44, 0.6);
    width: 100%;
    text-align: center;
  }

  ${mq["md"]} {
    width: clamp(3rem, 10vw, 7rem);
  }
`;

const playerPoints = css`
  --width: clamp(1.7rem, 3vw, 2.1rem);
  position: absolute;
  top: clamp(2.5rem, 6.5vw, 3.4rem);
  left: 50%;
  translate: -50% 0;

  display: inline-block;
  width: var(--width);
  line-height: var(--width);
  aspect-ratio: 1/1;
  background-color: ${primaryColor};
  border-radius: 99999px;
  box-shadow: 0 9.41px 9.41px 0 rgba(0, 0, 0, 0.25);
  font-size: clamp(0.65rem, 1.9vw, 1.1rem);
  font-weight: 600;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const skeletonPlayerInfoStyle = css`
  background: #1c1c2d;
`;

const skeletonPlayerPoints = css`
  background-color: ${bgColor};
  color: #3b3b63;
  z-index: 1000;
`;
