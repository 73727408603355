export const SESSION_STORAGE = {
  TEMP_TEAM_ID: "tempTeamId",
  TEMP_SELECTED_LEAGUE_ID: "tempSelectedLeague",
  CURRENT_GAME_WEEK: "currentGameWeek",
  URL_HISTORY: "urlHistory",
} as const;

export const ENV_OPTIONS = {
  DEV: "development",
  PROD: "production",
  PREVIEW: "preview",
  LOCAL: "local",
} as const;
