import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
import { ENV_OPTIONS } from "../config/app.config";

/**
 * The is a component that helps in debugging the react router, works only in dev
 * The Component wrap the entre app and log the any navigation that happen
 */

export const DebugRouter = ({ children }: { children: any }) => {
  const location = useLocation();
  const navigationType = useNavigationType(); // "POP" | "PUSH" | "REPLACE"

  useEffect(() => {
    if (import.meta.env.VITE_ENVIRONMENT === ENV_OPTIONS.LOCAL) {
      console.log({
        Route: location.pathname + location.search,
        State: location.state,
        navigationType,
      });
    }
  }, [location.pathname]);

  return children;
};
