import { useQuery } from "react-query";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { CHIPS } from "../../constants/highlightsPage.constants";

export type TCategoryId = keyof typeof CHIPS;

export interface ICategory {
  category_display_name: string;
  category_icon: string; // Can be empty string
  category_id: TCategoryId;
  category_order: number;
  accent_color?: string;
  _id: string;
}

/** useQuery hook that fetch highlights tabs */
export const useGetHighlightsTabs = () => {
  const getTabs = async () => {
    const { data: tabsData } = await axiosInstance.get(
      FPL_CHAMP_ENDPOINTS.CATEGORIES
    );
    const newTabs: ICategory[] = [...tabsData];
    return newTabs.sort((a, b) => a.category_order - b.category_order);
  };

  const {
    data: tabs,
    isLoading,
    isError,
  } = useQuery({
    queryFn: getTabs,
    queryKey: ["tabs"],
    cacheTime: Infinity,
  });

  return [tabs, isLoading, isError] as const;
};
