import { useState } from "react";
import { Helmet } from "react-helmet";
import { MINI_LEAGUE_PAGE } from "../../constants/mini-league.constants";
import { SelectGameWeekDropDown } from "../../components";
import {
  CaptainsTable,
  ManagerListModal,
  MiniLeagueCarousel,
} from "./components";
import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import {
  IMiniLeagueCaptainsTableRes,
  useGetMiniLeagueCaptainTable,
} from "../../utils/hooks/useGetMiniLeagueCaptainTable";
import { GridEventListener } from "@mui/x-data-grid";

interface IManagerList {
  ownedBy: IMiniLeagueCaptainsTableRes["owned_by"];
  playerName: IMiniLeagueCaptainsTableRes["name"];
}

/** Renders mini league captains page */
export const MiniLeagueCaptainsPage = () => {
  const { miniLeagueCaptainsCardData } = useGetMiniLeagueCaptainTable();
  const [managersList, setManagersList] = useState<null | IManagerList>(null);

  const handleTableRowClick: GridEventListener<"rowClick"> = ({
    row,
  }: {
    row: IMiniLeagueCaptainsTableRes;
  }) => {
    setManagersList({ ownedBy: row.owned_by, playerName: row.name });
  };

  const handleModalClose = () => {
    setManagersList(null);
  };

  const handleCardBtnClick = (row: IMiniLeagueCaptainsTableRes) => {
    setManagersList({ ownedBy: row.owned_by, playerName: row.name });
  };

  return (
    <Stack css={rootStyles}>
      <Helmet>
        <title>{MINI_LEAGUE_PAGE.META.TITLE}</title>
      </Helmet>

      {managersList && (
        <ManagerListModal
          isCaptain
          open={managersList !== null}
          managersList={managersList.ownedBy}
          playerName={managersList.playerName}
          onClose={handleModalClose}
        />
      )}

      <div>
        <SelectGameWeekDropDown />
      </div>

      <MiniLeagueCarousel
        miniLeagueCardData={miniLeagueCaptainsCardData!}
        onBtnClick={handleCardBtnClick}
      />

      <CaptainsTable onRowClick={handleTableRowClick} />
    </Stack>
  );
};

const rootStyles = css`
  gap: 1rem;
`;
