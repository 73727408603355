export const HIGHLIGHT_IDS = {
  POINTS: "points",
  TRANSFERS: "transfers",
  TRANSFERS_OUTCOME: "transfers-outcome",
  TEAM_VALUE: "team-value",
  TEAM_VALUE_CHANGE: "team-value-change",
  GOALS_SCORED: "goals-scored",
  OVERALL_RANK_CHANGE: "overall-rank-change",
  LEAGUE_RANK_CHANGE: "league-rank-change",
  LOST_ON_BENCH: "lost-on-bench",
  UNIQUE_CAPTAIN: "unique-captain",
  LUCKIEST: "luckiest",
  RANK_INCREASE_STREAK: "rank-increase-streak",
  RANK_DECREASE_STREAK: "rank-decrease-streak",
} as const;

export const CHIPS = {
  amusing: "Amusing Highlights",
  stinking: "Sticking Highlights",
  basic: "Basic Highlights",
  all: "All Highlights",
} as const;

export const HIGHLIGHTS_PAGE_CONTENT = {
  META: {
    TITLE: "Highlights",
  },
  TITLE: "Highlights",
  SELECT_GAME_WEEK_LABEL: "Select Phase:",
};

export const HIGHLIGHTS_ERROR_CARD = {
  TITLE: "League size is too big to calculate",
  SUB_TITLE: "Please select another league or try again later",
};
