import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { css } from "@emotion/react";
import triangle from "../assets/auth-pages/triangle.svg";
import { transition_all } from "../config/styles";

interface IAccordion {
  title: string;
  children: React.ReactNode;
}

/**
 * A single accordion component that take a title as a string prop and children as ResectNode
 */

export const Accordion = (props: IAccordion) => {
  return (
    <MuiAccordion elevation={0} disableGutters css={accordionStyle}>
      <AccordionSummary>
        <img src={triangle} alt="" />
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </MuiAccordion>
  );
};

const accordionStyle = css`
  background-color: transparent;
  color: white;
  box-shadow: none;
  border: 0;
  margin: 0;

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    margin: 0;

    img {
      ${transition_all}
      width: 0.8rem;
    }

    &.Mui-expanded > img {
      transform: rotate(90deg);
    }

    p {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0 0.5rem;
  }
`;
