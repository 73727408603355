export const MINI_LEAGUE_PAGE = {
  META: {
    TITLE: "Mini League",
  },
  TITLE: "Mini League",
  TABLE: {
    TITLE: "Standings",
    BTN_TITLE: "Show Previous Captains",
    SMALL_BTN_TITLE: "Prev. Captains",
  },
  CAPTAINS: {
    CARD: {
      TITLE: "Most Captained",
      BTN: "Managers that captained him",
    },
    TABLE: {
      TITLE: "League Captains",
      SUB_TITLE:
        "List of how many times the most popular captains were picked by each manager",
    },
  },
  MOST_HURTFUL: {
    TABLE: {
      TITLE: "Most Hurtful",
      SUB_TITLE:
        "Players that you don’t own that are the most popular with your selected league",
    },
    CARD: {
      TITLE: "Most Hurtful",
      CAPTAIN_DESCRIPTION: (ownedPercentage: string, count: number | string) =>
        `Captained by ${ownedPercentage} of the league (${count} teams)`,
      MOST_HURTFUL_DESCRIPTION: (
        ownedPercentage: string,
        count: number | string
      ) => `Owned by ${ownedPercentage} of the league (${count} teams)`,
      BTN: "Managers that own him",
    },
  },
  NEW_ENTIRES: {
    TABLE: { TITLE: "New Entries" },
  },
};
