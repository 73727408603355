import { css } from "@emotion/react";
import { Stack } from "@mui/system";
import { Skeleton } from "./Skeleton";
import { secondaryBgColor } from "../config/theme";

interface IAppTableSkeletonProps {
  numberOfRows?: number;
}

/** Renders table loading skeleton */
export const AppTableSkeleton = (props: IAppTableSkeletonProps) => {
  return (
    <>
      {Array.from({ length: props.numberOfRows || 11 }, (_, index) => (
        <Stack key={index} css={tableSkeletonStyle}>
          <Skeleton />
          <Skeleton css={midRowStyle} />
          <Skeleton />
        </Stack>
      ))}
    </>
  );
};

const tableSkeletonStyle = css`
  flex-direction: row;
  gap: 1rem;
  height: 3.25rem;
  padding: 0.75rem 1rem;
  border-bottom: 2px solid #181829;

  &:hover {
    background-color: #1a1a24;
  }

  background-color: ${secondaryBgColor}55;

  &:last-of-type {
    border-radius: 0 0 1rem 1rem;
  }
`;

const midRowStyle = css`
  flex-basis: 150%;
`;
