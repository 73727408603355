import { Helmet } from "react-helmet";
import { css } from "@emotion/react";
import { container, mq } from "../../../config/styles";
import { TeamDetailsForm } from "./TeamDetailsForm";
import { TEAM_DETAIL_PAGE } from "../../../constants/auth-pages.constants";
import { Header, AuthDivider, FormContainer } from "../components";
import { TeamDetailsAccordion } from "../components/TeamDetailsAccordion";
import { useUserContext } from "../../../context/UserContext";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../../config/routes";
import { SESSION_STORAGE } from "../../../config/app.config";
import { useRef } from "react";

/** Renders the team Details page  */
export const TeamDetailsPage = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const redirectURL = sessionStorage.getItem(SESSION_STORAGE.URL_HISTORY);
  const locationState = useRef(location.state); // location is reset

  if (user === null) {
    return <Navigate to={ROUTES.INTRO_PAGE} />;
  }

  if (
    user &&
    user.teamId &&
    (locationState.current ? !locationState.current.changeTeamButton : true)
  ) {
    return <Navigate to={redirectURL || "/"} />;
  }

  return (
    <FormContainer>
      <main css={root}>
        <Helmet>
          <title>{TEAM_DETAIL_PAGE.META.TITLE}</title>
        </Helmet>
        <Header
          title={TEAM_DETAIL_PAGE.TITLE}
          subtitle={TEAM_DETAIL_PAGE.SUBTITLE}
        />

        <AuthDivider />

        <TeamDetailsForm />

        <TeamDetailsAccordion />
      </main>
    </FormContainer>
  );
};

const root = css`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  ${mq["md"]} {
    margin-top: 0;
  }
`;
