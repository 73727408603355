import { css } from "@emotion/react";
import logo from "../../../assets/identity/logo.svg";
import { divider } from "../../../config/styles";

export const AuthDivider = () => {
  return (
    <div css={separator}>
      <hr />
      <img src={logo} alt="FPL-Champ Logo" />
      <hr />
    </div>
  );
};

const separator = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  min-width: 100%;

  img {
    width: 2rem;
    object-fit: cover;
  }

  hr {
    flex-grow: 1;
    ${divider}
  }
`;
