export const LeaguesIcon = ({
  fill = "#8E8E8E",
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.27461 18.7936V25.5421H1.99284V18.7936H8.27461Z"
        stroke={fill}
        strokeWidth="1.1849"
      />
      <path
        d="M26.008 21.5924V25.5409H19.7262V21.5924H26.008Z"
        stroke={fill}
        strokeWidth="1.1849"
      />
      <path
        d="M20.44 3.80852H18.9467V2.35252C18.9467 2.08462 18.7237 1.86719 18.4489 1.86719H10.4844C10.2097 1.86719 9.98667 2.08462 9.98667 2.35252V3.80852H8.49333C7.63666 3.80852 7 4.42926 7 5.26452C7 5.55426 7.08363 5.89303 7.24491 6.13618C7.71182 6.83943 8.53863 8.30174 10.3326 8.88947C10.8682 10.1382 11.9833 11.0885 13.3492 11.4312L12.5169 13.3249C12.4885 13.388 12.4756 13.4526 12.4756 13.5152C12.4751 13.771 12.6866 14.0005 12.9733 14.0005H15.96C16.2467 14.0005 16.4583 13.771 16.4578 13.5152C16.4578 13.4526 16.4448 13.388 16.417 13.3249L15.5842 11.4312C16.9501 11.0885 18.0651 10.1382 18.6007 8.88947C20.3947 8.30174 21.2215 6.83943 21.6884 6.13618C21.8497 5.89303 21.9333 5.55426 21.9333 5.26452C21.9333 4.42926 21.2967 3.80852 20.44 3.80852ZM8.49333 5.26452H9.98667V7.09423C9.32811 6.60792 8.89554 5.88186 8.49333 5.26452ZM18.9467 7.09423V5.26452H20.44C20.0378 5.88186 19.6052 6.60792 18.9467 7.09423Z"
        fill={fill}
      />
      <path
        d="M10.3923 26.0078V15.526H17.6074V26.0078H10.3923Z"
        stroke={fill}
        strokeWidth="1.1849"
      />
      <path
        d="M11.2002 3.98373C11.2002 3.32933 11.7307 2.79883 12.3851 2.79883H16.5486C17.203 2.79883 17.7335 3.32932 17.7335 3.98372V6.96237C17.7335 8.27117 16.6725 9.33216 15.3637 9.33216H13.57C12.2612 9.33216 11.2002 8.27117 11.2002 6.96237V3.98373Z"
        fill="#2C2D3F"
      />
    </svg>
  );
};
