import { Stack } from "@mui/material";
import { shadow, transition_all } from "../config/styles";
import { white } from "../config/theme";
import { css } from "@emotion/react";
import { Skeleton } from "./Skeleton";
import { Link } from "react-router-dom";

interface ICardChip {
  image: string;
  title: string;
  clickable?: boolean;
  to?: string;
}

/**
 * Card Chip component used in Top Captain card
 */
export const CardChip = (props: ICardChip) => {
  return (
    <Stack
      component={props.clickable ? Link : "span"}
      css={labelStyle(props.clickable!)}
      to={props.to}
    >
      <img src={props.image} /> {props.title}
    </Stack>
  );
};

/**
 * Loading Skeleton for Card Chip component
 */
export const CardChipSkeleton = () => {
  return <Skeleton sx={{ borderRadius: "99999px", height: "1.6rem" }} />;
};

const labelStyle = (clickable: boolean) => css`
  ${transition_all}
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 999999px;
  padding: 0.25rem 0.5rem 0.15rem;
  background-color: ${white};
  color: #181829;
  width: fit-content;
  font-weight: 700;
  font-size: clamp(0.75rem, 1.5vw, 1.15rem);

  ${clickable &&
  css`
    :hover {
      cursor: pointer;
      box-shadow: ${shadow};
      background-color: #ededed;
    }
  `}

  img {
    width: clamp(1rem, 1.8vw, 1.5rem);
  }
`;
