import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button } from "../../../components";
import { subscribeInputYupSchema } from "../utils/subscribeInput.validation";
import { css } from "@emotion/react";
import { bgColor, secondaryColor, statusColor } from "../../../config/theme";
import { CircularProgress, Stack } from "@mui/material";
import { COMING_SOON_PAGE } from "../../../constants/coming-soon-page-constants/comingSoon.constants";
import { axiosInstance } from "../../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../../config/fplChampEndpoints.constants";
import { animatePulse } from "../../../config/styles";

/** Input field that adds the user email to marketing collection in DB */
export const SubscribeInput = () => {
  const initialValues = {
    email: "",
  };

  const submitForm = async (values: any, { setSubmitting, setErrors }: any) => {
    try {
      setSubmitting(true);
      await axiosInstance.post(
        FPL_CHAMP_ENDPOINTS.SUBSCRIBE_TO_EMAIL_MARKETING,
        values
      );
    } catch (e: any) {
      setErrors({ email: e.message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={subscribeInputYupSchema}
      onSubmit={submitForm}
    >
      {({ isSubmitting }) => (
        <Form css={formStyle}>
          <label>{COMING_SOON_PAGE.INPUT_LABEL}</label>
          <Stack direction={"row"} css={inputStyle(isSubmitting)}>
            <Field name="email" placeholder="Email" />
            <Button variant="gradient" type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <CircularProgress color="primary" size={30} />
              ) : (
                COMING_SOON_PAGE.SUBSCRIBE
              )}
            </Button>
          </Stack>
          <span css={errorFieldStyle}>
            <ErrorMessage name="email" />
          </span>
        </Form>
      )}
    </Formik>
  );
};

const inputStyle = (isSubmitting: boolean) => css`
  --border-radius: 0.75rem;

  height: 4.375rem;
  input {
    height: 100%;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    border: 2px solid ${secondaryColor};
    border-right: none;
    padding: 1.25rem;
    background-color: ${bgColor};
    color: white;
    outline: none;
    ::placeholder {
      color: #fff;
    }
  }

  button {
    height: 100%;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  ${isSubmitting &&
  css`
    ${animatePulse}
  `}
`;

const formStyle = css`
  label {
    font-size: 1.25rem;
    font-weight: bold;
  }
`;

const errorFieldStyle = css`
  color: ${statusColor.error};
  margin-left: 0.25rem;
  margin-top: 0.25rem;
  font-size: 0.875rem;
`;
