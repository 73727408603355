import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { secondaryColor } from "../config/theme";

/** Render the team cell in MUI DataGrid table */
export const AppTableTeamCell = ({
  mangerName,
  teamName,
  accentColor,
}: {
  mangerName: string;
  teamName: string;
  accentColor?: string;
}) => {
  return (
    <Stack css={teamCellStyle(accentColor)}>
      <Typography variant="h1">{mangerName} </Typography>
      <Typography variant="h2">{teamName}</Typography>
    </Stack>
  );
};

const teamCellStyle = (accentColor?: string) => css`
  justify-content: center;
  height: 100%;
  white-space: normal;
  h1 {
    font-size: inherit;
    font-weight: 700;
    color: white;
    color: ${accentColor || secondaryColor};
  }

  h2 {
    font-size: inherit;
  }
`;
