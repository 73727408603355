import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { PRIVACY_PAGE_CONTENT } from "../../constants/privacyAndTerms.constants";
import { secondaryColor } from "../../config/theme";
import { mq } from "../../config/styles";
import { Helmet } from "react-helmet";

/** Renders the privacy page */
export const PrivacyPage = () => {
  return (
    <Stack css={root}>
      <Helmet>
        <title>{PRIVACY_PAGE_CONTENT.META.TITLE}</title>
      </Helmet>
      <Typography variant="h1" css={titleStyle}>
        {PRIVACY_PAGE_CONTENT.TITLE}
      </Typography>

      <section>
        <Typography css={sectionTitleStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.TITLE}
        </Typography>
        <Typography css={textStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.INTRO}
        </Typography>

        {/* Info we collect */}
        <Typography css={textStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.INFO_WE_COLLECT_SECTION.TITLE}
        </Typography>
        <Typography css={textStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.INFO_WE_COLLECT_SECTION.INTRO}
        </Typography>
        <Typography css={listStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.INFO_WE_COLLECT_SECTION.INFO_WE_COLLECT_LIST.map(
            (item) => (
              <Typography key={item.TITLE} css={textStyle}>
                <span css={boldStyle}>{item.TITLE}</span> {item.PARAGRAPH}
              </Typography>
            )
          )}
        </Typography>

        {/* exclamation */}
        <Typography>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.EXCLAMATION_SECTION.TITLE}
        </Typography>
        <Typography css={listStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.EXCLAMATION_SECTION.EXCLAMATION_LIST.map(
            (item) => (
              <Typography key={item.TITLE} css={textStyle}>
                <span css={boldStyle}>{item.TITLE}</span> {item.PARAGRAPH}
              </Typography>
            )
          )}
        </Typography>
      </section>

      {/* Info we collect */}
      <section>
        <Typography css={sectionTitleStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.INFO_WE_COLLECT_SECTION.TITLE}
        </Typography>
        <Typography css={textStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.INFO_WE_COLLECT_SECTION.INTRO}
        </Typography>
        <Typography css={listStyle}>
          {PRIVACY_PAGE_CONTENT.INTRODUCTION.INFO_WE_COLLECT_SECTION.INFO_WE_COLLECT_LIST.map(
            (item) => (
              <Typography key={item.TITLE} css={textStyle}>
                <span css={boldStyle}>{item.TITLE}</span> {item.PARAGRAPH}
              </Typography>
            )
          )}
        </Typography>
      </section>

      {/* How we use your info */}
      <section>
        <Typography css={sectionTitleStyle}>
          {PRIVACY_PAGE_CONTENT.HOW_WE_USE_YOUR_INFO.TITLE}
        </Typography>
        <Typography>
          {PRIVACY_PAGE_CONTENT.HOW_WE_USE_YOUR_INFO.INTRO}
        </Typography>
        <ul css={unorderedListStyle}>
          {PRIVACY_PAGE_CONTENT.HOW_WE_USE_YOUR_INFO.HOW_WE_USE_YOUR_INFO_LIST.map(
            (item) => (
              <li key={item} css={textStyle}>
                {item}
              </li>
            )
          )}
        </ul>
      </section>

      {/* Data Security */}
      <section>
        <Typography css={sectionTitleStyle}>
          {PRIVACY_PAGE_CONTENT.DATA_SECURITY.TITLE}
        </Typography>
        <Typography css={textStyle}>
          {PRIVACY_PAGE_CONTENT.DATA_SECURITY.INTRO}
        </Typography>
      </section>

      {/* Your Choice */}
      <section>
        <Typography css={sectionTitleStyle}>
          {PRIVACY_PAGE_CONTENT.YOUR_CHOICE.TITLE}
        </Typography>
        <ul css={unorderedListStyle}>
          {PRIVACY_PAGE_CONTENT.YOUR_CHOICE.YOUR_CHOICES_LIST.map((item) => (
            <li key={item.TITLE} css={textStyle}>
              <span css={boldStyle}>{item.TITLE}</span> {item.PARAGRAPH}
            </li>
          ))}
        </ul>
      </section>

      {/* Children privacy */}
      <section>
        <Typography css={sectionTitleStyle}>
          {PRIVACY_PAGE_CONTENT.CHILDREN_PRIVACY.TITLE}
        </Typography>
        <Typography css={textStyle}>
          {PRIVACY_PAGE_CONTENT.CHILDREN_PRIVACY.INTRO}
        </Typography>
      </section>

      {/* Changes to policy */}
      <section>
        <Typography css={sectionTitleStyle}>
          {PRIVACY_PAGE_CONTENT.CHANGES_TO_POLICY.TITLE}
        </Typography>
        <Typography css={textStyle}>
          {PRIVACY_PAGE_CONTENT.CHANGES_TO_POLICY.INTRO}
        </Typography>
      </section>

      {/* Contact us */}
      <section>
        <Typography css={sectionTitleStyle}>
          {PRIVACY_PAGE_CONTENT.CONTACT.TITLE}
        </Typography>
        <Typography css={textStyle}>
          {PRIVACY_PAGE_CONTENT.CONTACT.INTRO}
        </Typography>
      </section>
    </Stack>
  );
};

const root = css`
  color: #dddedf;
  gap: 1rem;
  ${mq["md"]} {
    gap: 1.5rem;
  }
`;

const titleStyle = css`
  color: ${secondaryColor};
  font-weight: 600;
  font-size: 1.25rem;
  ${mq["md"]} {
    font-size: 1.5rem;
  }
`;

const sectionTitleStyle = css`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  ${mq["md"]} {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
`;

const listStyle = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-block: 0.5rem;
  ${mq["md"]} {
    gap: 0.75rem;
    margin-block: 0.75rem;
  }
`;

const boldStyle = css`
  font-weight: bold;
`;

const unorderedListStyle = css`
  margin: 0 0 1rem 1rem;
  li {
    list-style-type: disc;
  }
`;

const textStyle = css`
  font-size: 0.75rem;
  ${mq["md"]} {
    font-size: 1rem;
  }
`;
