import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { AppCard } from ".";
import { redColor, white } from "../config/theme";
import errorIcon from "../assets/icons/warning.svg";
import { poppinsFontFamily } from "../config/styles";
import { HIGHLIGHTS_ERROR_CARD } from "../constants/highlightsPage.constants";

export const HighlightsErrorCard = () => {
  return (
    <AppCard accentColor={redColor}>
      <Stack css={rootStyles}>
        <Stack css={textContainerStyles}>
          <Typography variant="h1" css={titleStyles}>
            {HIGHLIGHTS_ERROR_CARD.TITLE}
          </Typography>
          <Typography variant="h2" css={subTitleStyles}>
            {HIGHLIGHTS_ERROR_CARD.SUB_TITLE}
          </Typography>
        </Stack>
        <img src={errorIcon} css={imgStyles} />
      </Stack>
    </AppCard>
  );
};

const rootStyles = css`
  flex: 1;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const textContainerStyles = css`
  flex: 1;
`;

const titleStyles = css`
  ${poppinsFontFamily}
  color: ${redColor};
  font-size: clamp(1.25rem, 3.3vw, 2.6rem);
  font-weight: 800;
`;

const subTitleStyles = css`
  color: ${white};
  font-size: 0.75rem;
  font-size: clamp(0.85rem, 1.5vw, 1.25rem);
  font-weight: 600;
`;

const imgStyles = css`
  flex: 1;
`;
