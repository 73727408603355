import { css } from "@emotion/react";
import { mq, transition_all } from "../config/styles";
import { Field } from "formik";
import { ErrorMessage } from "./ErrorMessage";
import check from "../assets/icons/check.svg";
import { IconButton } from "@mui/material";

interface ICheckbox {
  children: React.ReactNode;
  name: string;
}

/**
 * This component renders a custom checkbox element with an integrated label and error message display.
 * It utilizes the `Field` component from `formik` to handle form integration and the `ErrorMessage` component (assumed to be defined elsewhere) for error display.
 *
 * @param {ICheckbox} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed as the checkbox label.
 * @param {string} props.name - The name of the underlying `Field` component, used for form handling and error association.
 * @param {...object} props - Any other props that can be passed to the underlying `Field` component.
 *
 * @returns {JSX.Element} - Renders a styled checkbox element with label, error message, and optional checkmark icon.
 */
export const Checkbox = ({ children, name, ...props }: ICheckbox) => {
  return (
    <div>
      <div css={checkboxContainer}>
        <IconButton css={checkbox}>
          <label>
            <Field type="checkbox" name={name} {...props} />
            <img src={check} alt="check" />
          </label>
        </IconButton>
        <span>{children}</span>
      </div>
      <ErrorMessage name={name} />
    </div>
  );
};

const checkboxContainer = css`
  display: flex;
  gap: 0.875rem;
  align-items: start;
  font-size: clamp(0.75rem, 5vw, 1rem);
`;

const checkbox = css`
  width: fit-content;
  padding: 0;
  label {
    cursor: pointer;
    input {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f9fe;
    min-width: 1.5rem;
    aspect-ratio: 1/1;
    border-radius: 0.3125rem;
    font-size: 0.75rem;
    ${mq["md"]} {
      font-size: 0.875rem;
    }
  }

  img {
    ${transition_all}
    opacity: 0;
    pointer-events: none;
  }

  input:checked ~ img {
    opacity: 1;
  }
`;
