import { useQuery } from "react-query";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useUserContext } from "../../context/UserContext";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";

export interface ITeamInfo {
  fpl_manager_name: string;
  name: string;
  started_event: number;
  active_chip: null | string;
  automatic_subs: any[];
  number_of_players: number;
  entry_history: {
    event: number;
    points: number;
    total_points: number;
    players_points: number;
    rank: number;
    rank_sort: number;
    overall_rank: number;
    previous_overall_rank: number;
    percentile_rank: number | null;
    bank: number;
    value: number;
    event_transfers: number;
    event_transfers_cost: number;
    points_on_bench: number;
    all_season_transfers: number;
  };
  picks: {
    element: number;
    player_in: boolean;
    player_out: boolean;
    sub_index: number;
    position: number;
    multiplier: number;
    is_captain: boolean;
    is_vice_captain: boolean;
    name: string;
    field_position: string;
    team: number;
    team_name: string;
    op_team_name: string;
    gw_total_points: number;
    shirt_link: string;
    gk_shirt_link: string;
  }[];
}

interface IUseGetTeamInfo {
  teamId: string;
}

/** useQuery hook that fetch the team info */
export const useGetTeamInfo = (props?: IUseGetTeamInfo) => {
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();

  const teamId = props ? props.teamId : user?.teamId;

  const getTeamInfo = async () => {
    if (
      user?.teamId &&
      teamId &&
      searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)
    ) {
      const { data } = await axiosInstance.get<ITeamInfo>(
        FPL_CHAMP_ENDPOINTS.TEAM_DATA_URL(
          teamId,
          searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!
        )
      );
      return data;
    }
  };

  const { data: teamInfo, isLoading } = useQuery({
    queryFn: getTeamInfo,
    queryKey: [teamId, searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)],
  });

  return { teamInfo, isLoading } as const;
};
