import { Stack, Typography } from "@mui/material";
import error from "../assets/icons/warning.svg";
import { bgColor } from "../config/theme";
import { css } from "@emotion/react";
import { Button } from "./Button";
import { FALLBACK_UI } from "../constants/global.constants";
import { useNavigateState } from "../utils/hooks/useNavigateState";

interface IErrorBoundaryFallbackUI {
  resetState(): void;
}

export const ErrorBoundaryFallbackUI = (props: IErrorBoundaryFallbackUI) => {
  const navigate = useNavigateState();
  const handleButtonClick = () => {
    props.resetState();
    navigate("/");
  };

  return (
    <Stack css={loadingScreenContainerStyles}>
      <img src={error} />
      <Typography variant="h1">{FALLBACK_UI.TITLE}</Typography>

      <div>
        <Button onClick={handleButtonClick} button="gradient">
          {FALLBACK_UI.BTN_TEXT}
        </Button>
      </div>
    </Stack>
  );
};

const loadingScreenContainerStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: ${bgColor};

  img {
    width: clamp(10rem, 25vw, 20rem);
  }

  h1 {
    max-width: 35rem;
    font-size: clamp(1.2rem, 3vw, 2rem);
    text-align: center;
  }

  div {
    width: 15rem !important;
  }
`;
