import { Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { AppTable, AppTableSkeleton } from "../../../components";
import { columnNumber } from "../../../utils/muiTableColumns";
import { useGetMiniLeagueMostHurtfulTable } from "../../../utils/hooks/useGetMiniLeagueMostHurtful";
import {
  arrowColumn,
  hurtfulPlayer,
  OwnershipColumn,
} from "../../../utils/tableColumns/miniLeagueTableColumns";
import { MINI_LEAGUE_PAGE } from "../../../constants/mini-league.constants";
import { GridEventListener } from "@mui/x-data-grid";
import { tableHeaderBgColor } from "../../../config/theme";

interface IMostHurtfulTable {
  onRowClick: GridEventListener<"rowClick">;
}

/** Renders a table in the most hurt player tab */
export const MostHurtfulTable = (props: IMostHurtfulTable) => {
  const { mostHurtfulPlayerTableData, isLoading } =
    useGetMiniLeagueMostHurtfulTable();

  const headers = [columnNumber, hurtfulPlayer, OwnershipColumn, arrowColumn];

  return (
    <div>
      <Stack css={headerContainerStyle}>
        <Typography variant="h1" css={tableTitleStyle}>
          {MINI_LEAGUE_PAGE.MOST_HURTFUL.TABLE.TITLE}
        </Typography>
        <Typography css={tableSubTitleStyle}>
          {MINI_LEAGUE_PAGE.MOST_HURTFUL.TABLE.SUB_TITLE}
        </Typography>
      </Stack>
      {!isLoading && mostHurtfulPlayerTableData ? (
        <AppTable
          dividerColor="#2E2F3E"
          onRowClick={props.onRowClick}
          rows={mostHurtfulPlayerTableData}
          columns={headers}
          getRowId={(row) => row.name.web_name}
        />
      ) : (
        <AppTableSkeleton />
      )}
    </div>
  );
};

const headerContainerStyle = css`
  gap: 0.5rem;
  background: ${tableHeaderBgColor};
  border-radius: 1rem 1rem 0 0;
  padding-inline: 1rem;
  padding-block: 1rem 0.3rem;
`;

const tableTitleStyle = css`
  font-size: clamp(1.125rem, 5vw, 2rem);
  font-weight: 500;
`;

const tableSubTitleStyle = css`
  font-size: clamp(0.675rem, 1.5vw, 0.9rem);
  color: #dfdfdf;
`;
