import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useQuery } from "react-query";
import { IFplManager } from "../types/types";

export interface IMiniLeagueCaptainsTableRes {
  team: {
    name: string;
    short_name: string;
    team_id: number;
  };
  name: {
    web_name: string;
    first_name: string;
    last_name: string;
  };
  position: string;
  count: number;
  ownedPercentage: string;
  shirtLink: string;
  gwHistoryPoints: { [key: string]: number | undefined };
  owned_by: IFplManager[];
}

/**
 * fetch mini league captains data if history is true fetch standing table history view
 * if history is false fetch captains table and captains cards
 */

export const useGetMiniLeagueCaptainTable = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const queryKey = [
    {
      leagueId: params[PATH_PARAMS.LEAGUE_ID]!,
      gw: searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
    },
  ];

  const queryFunction = async () => {
    if (
      params[PATH_PARAMS.LEAGUE_ID] &&
      searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)
    ) {
      const { data } = await axiosInstance.get<IMiniLeagueCaptainsTableRes[]>(
        FPL_CHAMP_ENDPOINTS.MINI_LEAGUE_CAPTAINS(
          params[PATH_PARAMS.LEAGUE_ID]!,
          searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!
        )
      );

      return data;
    }
  };

  const {
    isLoading,
    data: miniLeagueCaptains,
    isFetching,
  } = useQuery({
    queryKey: queryKey,
    queryFn: queryFunction,
  });

  return {
    miniLeagueCaptainsCardData: miniLeagueCaptains?.slice(0, 3),
    miniLeagueCaptainsTableData: miniLeagueCaptains,
    isLoading,
    isFetching,
  } as const;
};
