import {
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { css } from "@emotion/react";
import { bgColor, primaryColor, secondaryColor, white } from "../config/theme";
import { animatePulse } from "../config/styles";
import gameWeekArrow from "../assets/icons/game-week-arrows.svg";

type menuItemValueType = string | number | readonly string[] | undefined;
interface IDropDown {
  label: string;
  isLoading: boolean;
  loadingText?: string;
  onChange(event: SelectChangeEvent): void;
  value: any;
  items: { value: menuItemValueType; label: string }[];
}

/** The default DropDown Menu with default styles and options  */
export const AppDropDown = (props: IDropDown) => {
  return (
    <Stack css={SelectGameWeekDropDownContainer}>
      <Typography variant="h1">{props.label}</Typography>
      {props.isLoading ? (
        <Select
          endAdornment={<img src={gameWeekArrow} />}
          IconComponent={() => null}
          css={selectMenuStyle}
          MenuProps={{
            sx: {
              ".MuiList-root": {
                p: "0",
              },
              ".MuiPaper-root": {
                maxHeight: "15rem",
                mt: "1rem",
                border: "#dddedf 2px solid",
                borderRadius: "0.8rem",
                backgroundColor: bgColor,
              },
            },
          }}
          value={props.value}
          onChange={props.onChange}
        >
          {props.items.map((item, index) => (
            <MenuItem
              key={index}
              css={menuItemStyle}
              divider
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Stack css={skeletonStyle}>
          <Typography>{props.loadingText || "Loading"}</Typography>
          <CircularProgress color="secondary" size={15} />
        </Stack>
      )}
    </Stack>
  );
};
const SelectGameWeekDropDownContainer = css`
  h1 {
    color: ${secondaryColor};
    font-size: clamp(0.75rem, 2vw, 1rem);
  }
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const commonListAndLoadingSkeltonStyle = css`
  color: ${white};
  background-color: ${bgColor};
  border: #dddedf 2px solid;
  border-radius: 0.7rem;
  font-size: clamp(0.75rem, 2vw, 1rem);
  padding: 0.3rem 0.5rem;

  & > * {
    font-size: 0.75rem;
  }
`;

const skeletonStyle = css`
  ${animatePulse}
  ${commonListAndLoadingSkeltonStyle} 
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  p {
    font-size: 0.75rem;
  }
`;

const selectMenuStyle = css`
  ${commonListAndLoadingSkeltonStyle}
  gap: 0.4rem;

  .MuiSelect-select {
    padding: 0 !important;
  }

  svg {
    padding: 0 !important;
  }

  &.Mui-focused {
    outline: none;
    box-shadow: none;
  }
`;

const menuItemStyle = css`
  min-height: 0;
  padding-block: 0.5rem;
  color: white;
  background-color: ${bgColor};
  display: flex;
  gap: 0.5rem;
  position: relative;
  font-size: 0.75rem;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 2px;
    border-radius: 99999px;
    background-color: #8e8e8e;
  }
  &:last-of-type:after {
    display: none;
  }

  &.Mui-selected {
    background-color: ${primaryColor};
  }

  :hover,
  &.Mui-focusVisible {
    background-color: ${primaryColor};
  }

  &.Mui-selected:hover,
  &.Mui-selected.Mui-focusVisible {
    background-color: ${primaryColor}99;
  }
`;
