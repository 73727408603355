import { useEffect } from "react";
import { ROUTES } from "../config/routes";
import { useUserContext } from "../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";

/**
 *  This component acts as a gatekeeper, guarding specific routes within the application.
 *  It ensures that only authorized users with the necessary `teamId` can access those routes.
 *
 * @param {object} props - Contains information about what to protect.
 * @param {React.ReactNode} props.children - The content to be displayed if access is granted.
 * @returns {React.ReactNode} - Returns either the protected content or redirects to a login or onboarding page.
 */

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // push location (route) to router state
    if (user === null) {
      // null user is user not signed in
      navigate(ROUTES.INTRO_PAGE, {
        state: { from: location },
        replace: true,
      });
    } else if (user && !user.teamId) {
      // push location (route) to router state
      navigate(ROUTES.TEAM_ID_PAGE, {
        state: { from: location },
        replace: true,
      });
    }
  }, [user]);

  return <>{children}</>;
};
