export const COMING_SOON_PAGE = {
  META: {
    TITLE: "Coming Soon",
  },
  TITLE: "Coming Soon",
  SUBTITLE:
    "Get your boots laced up; we're currently cooking up something exciting.  Stay tuned for fun features coming your way!",
  INPUT_LABEL: "Get notified when we launch",
  SUBSCRIBE: "Subscribe",
  FOLLOW_US_ON_SOCIAL_MEDIA: "Follow us on social media for updates",
  COPY_RIGHT: "© Copyrights FPL Champ | All Rights Reserved",
};
