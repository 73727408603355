import { IconButton, css } from "@mui/material";
import { secondaryColor } from "../config/theme";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/** Render table expand icon button used in AppTable Component */
export const AppTableExpandCell = () => {
  return (
    <IconButton css={iconButtonStyle}>
      <ArrowForwardIcon css={iconStyle} />
    </IconButton>
  );
};

const iconButtonStyle = css`
  :hover {
    background-color: #9d98982d;
  }
`;

const iconStyle = css`
  color: ${secondaryColor};
`;
