import { SIDE_NAVIGATION_BAR_TITLES } from "../../constants/global.constants";
import { useLocation } from "react-router-dom";

import {
  FixturesIcon,
  HighlightsIcon,
  HomeIcon,
  LeaguesIcon,
  MyTeamIcon,
  TransfersIcon,
} from "../../components/icons";
import { useUserContext } from "../../context/UserContext";
import { useGetUrl } from "./useGetUrl";

/**
 * Custom hook that generates navigation items for navigation bar, with active status and generated URL from useGetUrl.
 * @returns {Array<Object>} - An array of navigation items with path, active status, title, and icon.
 */
export const useNavigationItem = () => {
  const { user } = useUserContext();
  const { pathname } = useLocation();
  const {
    getHomePath,
    getHighlightsPath,
    getTeamViewPath,
    getMiniLeagueStandingsPath,
    getFixturesPath,
    getTransfersPath,
  } = useGetUrl();

  return [
    {
      path: getHomePath(),
      isActivePath: pathname.includes("/home"),
      title: SIDE_NAVIGATION_BAR_TITLES.MAIN_NAVIGATION_TITLES.HOME,
      icon: HomeIcon,
    },
    {
      path: getHighlightsPath(),
      isActivePath: pathname.includes("/highlights"),
      title: SIDE_NAVIGATION_BAR_TITLES.MAIN_NAVIGATION_TITLES.HIGHLIGHTS,
      icon: HighlightsIcon,
    },
    {
      path: getMiniLeagueStandingsPath(),
      isActivePath:
        /^\/leagues\/[0-9]+(\/(captains|most-hurtful|new-entries))?$/.test(
          pathname.split("?")[0]
        ),
      title: SIDE_NAVIGATION_BAR_TITLES.MAIN_NAVIGATION_TITLES.LEAGUES,
      icon: LeaguesIcon,
    },
    {
      path: getTeamViewPath(user?.teamId!),
      isActivePath: pathname.includes("/team/"),
      title: SIDE_NAVIGATION_BAR_TITLES.MAIN_NAVIGATION_TITLES.POINTS,
      icon: MyTeamIcon,
    },
    {
      path: getFixturesPath(),
      isActivePath: pathname.includes("/fixture"),
      title: SIDE_NAVIGATION_BAR_TITLES.MAIN_NAVIGATION_TITLES.FIXTURES,
      icon: FixturesIcon,
    },
    {
      path: getTransfersPath(),
      isActivePath: pathname.split("/")[3] === "transfers",
      title: SIDE_NAVIGATION_BAR_TITLES.MAIN_NAVIGATION_TITLES.TRANSFERS,
      icon: TransfersIcon,
    },
  ];
};
